import api from '@api'
import { transformAudit } from '@/src/data/audit'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'

export function getAuditApi(filterFactor, moduleName, refId, pageSize, offset) {
  return api
    .post(
      `${moduleName}/${refId}/audit/search/byqual`,
      {
        qualDetails:
          filterFactor.startDate && filterFactor.endDate
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    'createdTime',
                    'between',
                    [filterFactor.startDate, filterFactor.endDate],
                    'long',
                    'db'
                  ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformAudit),
        total: data.totalCount,
      }
    })
}
