<template>
  <div v-if="isSupportChannelActive && initiated">
    <AIBotSupportChannel
      v-if="aiBotSupport && (!channel.status || channel.status === 'ai_bot')"
      :ongoing-chat="Boolean(channel.status)"
      :channel="channel"
      :history-messages="defaultMessages"
      @start-new-conversation="handleStartNewConversation"
    />
    <HumanSupportChannel
      v-if="
        (humanChatSupport && !aiBotSupport) ||
        (channel.status && channel.status !== 'ai_bot')
      "
      ref="humanSupportChannelRef"
      :socket-path="socketPath"
    />
  </div>
</template>

<script>
import Reverse from 'lodash/reverse'
import Bus from '@utils/emitter'
import { authComputed } from '@state/modules/auth'
import AIBotSupportChannel from './ai-bot/ai-bot-support-channel'
import HumanSupportChannel from './human-support-channel.vue'
import { isLoggedIn } from '@/src/utils/auth'

import {
  getRequesterChatSessionApi,
  createRequesterChatSessionApi,
  clearOldAndCreateNewChatSessionApi,
} from './chat-api'

export default {
  name: 'SupportChannel',
  components: { AIBotSupportChannel, HumanSupportChannel },
  inject: { chatConfigContext: { config: {} } },
  props: {
    chatBoxWidth: { type: Number, default: 305 },
    socketPath: { type: String, required: true },
  },
  data() {
    return {
      isSupportChannelActive: false,
      initiated: false,
      channel: {},
      defaultMessages: [],
    }
  },
  computed: {
    ...authComputed,
    aiBotSupport() {
      return this.chatConfigContext.config.botSupport
    },
    humanChatSupport() {
      return this.chatConfigContext.config.enabled
    },
  },
  watch: {
    'chatConfigContext.config': {
      handler: 'buildContainer',
      immediate: true,
    },
  },
  created() {
    const chatHandler = (payload) => {
      this.getCurrentChannel().then(() => {
        this.$refs.humanSupportChannelRef.handleShowBox()
      })
    }
    Bus.$on('app:transfer-chat', chatHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:transfer-chat', chatHandler)
    })
  },
  methods: {
    buildContainer() {
      const chatConfig = this.chatConfigContext.config
      if (chatConfig.enabled) {
        this.isSupportChannelActive = true
        this.$nextTick(() => {
          this.initiated = true
          this.getCurrentChannel()
        })
      } else {
        this.isSupportChannelActive = false
      }
    },
    getCurrentChannel() {
      if (isLoggedIn()) {
        return getRequesterChatSessionApi()
          .then((data) => {
            this.channel = data
            return data
          })
          .catch(() => {
            createRequesterChatSessionApi().then((data) => {
              this.channel = data.channel
              this.defaultMessages = Reverse(data.history || [])
            })
          })
      } else {
        clearOldAndCreateNewChatSessionApi().then((data) => {
          this.channel = data.channel
          this.defaultMessages = Reverse(data.history || [])
        })
      }
    },
    handleStartNewConversation(data) {
      this.channel = data.channel
      this.defaultMessages = Reverse(data.history || [])
    },
  },
}
</script>
