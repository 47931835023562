<template>
  <MRow :gutter="0" class="flex-1">
    <!-- <MCol v-if="analytics.stats" :size="12" class="my-2">
      <MRow class="my-2">
        <MCol v-if="analytics.approvalType" auto-size>
          <div>
            <small class="text-neutral-light">{{ $t('decision_type') }}</small>
          </div>
          <MTag
            variant="neutral-lighter"
            :closable="false"
            class="cursor-default"
          >
            {{ $t(analytics.approvalType) }}
          </MTag>
        </MCol>
        <MCol v-if="analytics.status" auto-size>
          <div>
            <small class="text-neutral-light">{{ $t('status') }}</small>
          </div>
          <StatusIcon :status="analytics.status" show-status />
        </MCol>
      </MRow>
      <MRow class="justify-between">
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('approval', 2) }}
            </small>
          </div>
          <span class="rounded-counter">
            {{ analytics.stats.total }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('approval_needed') }}
            </small>
          </div>
          <span class="rounded-counter info">
            {{ analytics.stats.needed }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('approved') }}
            </small>
          </div>
          <span class="rounded-counter success">
            {{ analytics.stats.approved }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('pending') }}
            </small>
          </div>
          <span class="rounded-counter warning">
            {{ analytics.stats.pending }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('ignored') }}
            </small>
          </div>
          <span class="rounded-counter neutral-lighter">
            {{ analytics.stats.ignored }}
          </span>
        </MCol>
        <MCol auto-size class="flex flex-col items-center">
          <div>
            <small class="text-neutral-light">
              {{ $t('total') }} {{ $tc('rejected') }}
            </small>
          </div>
          <span class="rounded-counter error">
            {{ analytics.stats.rejected }}
          </span>
        </MCol>
      </MRow>
      <MDivider />
    </MCol> -->
    <MCol :size="12">
      <FlotoCrudContainer
        :per-page="10"
        :scrollable="false"
        :fetch-fn="fetchAllApprovals"
        :update-fn="getApproval"
        :disabled="disabled"
      >
        <template v-slot:form>
          <span />
        </template>
        <template v-slot:list-items="{ items, update }">
          <MCollapse :bordered="false" class="flex-1">
            <MCollapsePanel v-for="approval in items" :key="approval.id">
              <template v-slot:header>
                <ApprovalHeader :disabled="disabled" :approval="approval" />
              </template>
              <ApprovalStages
                :disabled="disabled"
                :stage="approval"
                @refresh="update({ id: approval.id })"
              />
            </MCollapsePanel>
          </MCollapse>
        </template>
      </FlotoCrudContainer>
    </MCol>
  </MRow>
</template>

<script>
import {
  getResourceApprovalsApi,
  getResourceApprovalApi,
  getApprovalAnalyticsApi,
} from './approval-api'
import ApprovalHeader from './components/approval-header.vue'
import ApprovalStages from './components/approval-stages'
// import StatusIcon from './components/approval-status-icon.vue'

export default {
  name: 'ApprovalContainer',
  components: { ApprovalHeader, ApprovalStages },
  props: {
    disabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    resourceId: { type: Number, required: true },
  },
  data() {
    return {
      loading: true,
      analytics: {},
    }
  },
  methods: {
    getAnalytics() {
      this.loading = true
      this.analytics = {}
      getApprovalAnalyticsApi(this.moduleName, this.resourceId).then(
        (analytics) => {
          this.analytics = analytics
          this.loading = false
        }
      )
    },
    fetchAllApprovals(limit, offset) {
      return getResourceApprovalsApi(
        this.moduleName,
        this.resourceId,
        limit,
        offset
      )
    },
    getApproval(data) {
      return getResourceApprovalApi(
        this.moduleName,
        this.resourceId,
        data.id
      ).then((response) => {
        this.$emit('refresh')
        this.getAnalytics()
        return response
      })
    },
  },
}
</script>
