<template>
  <div class="flex flex-col">
    <MRow
      v-if="stage.totalStage > 1"
      class="my-4 items-center justify-center px-6"
    >
      <MCol auto-size>
        <b>{{ $tc('approval') }} {{ $tc('level') }}</b>
      </MCol>
      <MCol
        class="counter-steps"
        :style="{ maxWidth: `${stage.totalStage * 100}px` }"
      >
        <MSteps :active="stage.activeStage - 1" :status="currentStepStatus">
          <MStep v-for="stageNumber in allStages" :key="stageNumber">
            <template v-slot:title>
              <CounterStep
                :tooltip-text="
                  $t(
                    stage.activeStage === stageNumber &&
                      stage.stageStatus[stageNumber] === 'pending'
                      ? 'progress'
                      : stage.stageStatus[stageNumber]
                  )
                "
                :active="currentActiveApprovalStage === stageNumber"
                :status="
                  stage.activeStage === stageNumber &&
                  stage.stageStatus[stageNumber] === 'pending'
                    ? 'progress'
                    : stageNumber > stage.activeStage &&
                      stage.stageStatus[stage.activeStage] === 'rejected'
                    ? 'pending'
                    : getStatusForCounter(stage.stageStatus[stageNumber])
                "
              >
                <a
                  class="block"
                  @click="currentActiveApprovalStage = stageNumber"
                >
                  {{ stageNumber }}
                </a>
              </CounterStep>
            </template>
          </MStep>
        </MSteps>
      </MCol>
    </MRow>
    <ApprovalStage
      :key="currentActiveApprovalStage"
      :approval-id="stage.id"
      :disabled="currentActiveApprovalStage > stage.activeStage"
      :disabled-conversation="disabled"
      :hide-action="disabled"
      :show-subject="stage.totalStage > 1"
      :stage="currentActiveApprovalStage"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import Range from 'lodash/range'
import CounterStep from '@components/counter-step'
import ApprovalStage from './approval-stage'

export default {
  name: 'ApprovalStages',
  components: { ApprovalStage, CounterStep },
  props: {
    disabled: { type: Boolean, default: false },
    stage: { type: Object, required: true },
  },
  data() {
    return {
      currentActiveApprovalStage: this.stage.activeStage,
    }
  },
  computed: {
    allStages() {
      return Range(1, this.stage.totalStage + 1)
    },
    currentStepStatus() {
      if (
        ['ignored', 'approved', 'pre_approved'].indexOf(this.stage.status) >= 0
      ) {
        return 'finish'
      }
      if (this.stage.status === 'pending') {
        return 'process'
      }
      if (this.stage.status === 'rejected') {
        return 'error'
      }
      return 'process'
    },
  },
  watch: {
    'stage.activeStage': function(newValue) {
      this.currentActiveApprovalStage = newValue
    },
  },
  methods: {
    getStatusForCounter(status) {
      if (status.indexOf('approved') >= 0) {
        return 'success'
      }
      if (status === 'rejected') {
        return 'error'
      }
      if (status === 'ignored') {
        return 'success'
      }
      if (status === 'pending') {
        return 'pending'
      }
    },
  },
}
</script>
