<template>
  <FlotoContentLoader :loading="loading">
    <MRow>
      <MCol v-if="showSubject" :size="12" class="my-2">
        <MRow
          :gutter="0"
          class="p-2 bg-neutral-lightest rounded flex min-w-0 flex-no-wrap"
        >
          <MCol auto-size style="min-width: 60px;">
            <b>{{ $tc('level') }} {{ stage }}: </b>
          </MCol>
          <MCol class="min-w-0 flex">
            <b class="text-ellipsis">
              {{ currentStage.subject }}
            </b>
          </MCol>
          <MCol auto-size>
            <StatusIcon
              v-if="currentStage.status"
              :status="currentStage.status"
              size="lg"
            />
            <MTag
              variant="neutral-lighter"
              class="cursor-default"
              :closable="false"
            >
              {{ $t(currentStage.approvalType) }}
            </MTag>
          </MCol>
        </MRow>
      </MCol>
      <MCol
        v-if="currentStage.id"
        :size="disabled ? 12 : 6"
        style="min-height: 300px;"
      >
        <ApproverList
          :stage-id="currentStage.id"
          :disabled="disabled"
          v-bind="$attrs"
          @approver-action-taken="handleApprovalActionTaken"
        />
      </MCol>
      <MCol
        v-if="currentStage.id && !disabled"
        :size="6"
        style="min-height: 300px;"
      >
        <FlotoReadMore>
          {{ currentStage.description }}
        </FlotoReadMore>
        <MDivider />
        <ConversationContainer
          :disabled="disabledConversation"
          :allowed-types="['comment_on_approval']"
          module-name="approval_stage"
          :resource-id="currentStage.id"
          :rich-box-rows="8"
        />
      </MCol>
    </MRow>
  </FlotoContentLoader>
</template>

<script>
import ConversationContainer from '@components/conversation/conversation-container'
import { getApprovalStageInformationApi } from '../approval-api'
import StatusIcon from './approval-status-icon'
import ApproverList from './approver-list'

export default {
  name: 'ApprovalStage',
  components: { ConversationContainer, StatusIcon, ApproverList },
  props: {
    approvalId: { type: Number, required: true },
    stage: { type: Number, required: true },
    showSubject: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledConversation: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: true,
      currentStage: {},
    }
  },
  created() {
    getApprovalStageInformationApi(this.approvalId, this.stage).then((data) => {
      this.loading = false
      this.currentStage = data
    })
  },
  methods: {
    handleApprovalActionTaken() {
      getApprovalStageInformationApi(this.approvalId, this.stage).then(
        (data) => {
          if (data.status !== this.currentStage.status) {
            this.$emit('refresh')
            this.currentStage = data
          }
        }
      )
    },
  },
}
</script>
