<template>
  <div class="chat-box" :class="{ hidden: !isOpen }">
    <div class="chat-header" @click="isOpen = !isOpen">
      <div class="items-center flex">
        <MIcon name="comments" class="mr-2" /> {{ $tc('chat') }}
      </div>
      <div v-if="isOpen" class="chat-actions">
        <div @click.stop="isOpen = false">
          <MIcon name="times" />
        </div>
      </div>
    </div>
    <slot v-if="isOpen" name="connection-error" />
    <div class="chat-area page-background-color" :class="{ hidden: !isOpen }">
      <MTab v-model="currentTab">
        <MTabPane
          v-if="hasCollaboration"
          key="users"
          :tab="$tc('technician', 2)"
        />
        <MTabPane
          v-if="canSupport"
          key="ongoing"
          :tab="`${$t('ongoing')} ${$tc('chat')}`"
        />
      </MTab>
      <div class="scroll-wrapper">
        <div :key="currentTab">
          <div
            v-if="currentData.length"
            class="users-container"
            :class="{ disabled }"
          >
            <div
              v-for="u in currentData"
              :key="u.id"
              class="user-item"
              @click="handleUserClicked(u)"
            >
              <FlotoUserAvatar
                :size="30"
                style="min-width: 30px"
                :avatar="u.user ? u.user.avatar : u.avatar"
                show-online-status
                :is-online="onlineUsers.indexOf(u.user ? u.user.id : u.id) >= 0"
              />
              <div class="text-ellipsis ml-2">
                {{ u.user ? u.user.name : u.name }}
              </div>
            </div>
          </div>
          <div v-else>
            <FlotoNoData />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOngoingChatApi, transformRoom } from './chat-api'
import { TechnicianComputed } from '@state/modules/technician'
import { authComputed } from '@state/modules/auth'
import Bus from '@utils/emitter'

export default {
  name: 'ChatUsersList',
  props: {
    socket: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    eventPrefix: { type: String, default: '' },
    excludeIds: {
      type: Array,
      default() {
        return []
      },
    },
    onlineUsers: {
      type: Array,
      default() {
        return []
      },
    },
    left: { type: [Number], default: 0 },
    hasCollaboration: { type: Boolean, default: false },
    canSupport: { type: Boolean, default: false },
  },
  data() {
    return {
      isOpen: false,
      currentTab: this.hasCollaboration ? 'users' : 'ongoing',
      ongoingChats: [],
    }
  },
  computed: {
    ...TechnicianComputed,
    ...authComputed,
    currentData() {
      const tab = this.currentTab
      if (tab === 'users') {
        return this.allowedTechnicians
      }
      return this.ongoingChats
    },
    allowedTechnicians() {
      const excludedIds = this.excludeIds
      const onlineUsers = this.onlineUsers
      return this.technicianOptions.filter(
        (i) =>
          i.id !== 0 &&
          excludedIds.indexOf(i.id) === -1 &&
          onlineUsers.indexOf(i.id) >= 0
      )
    },
  },
  watch: {
    hasCollaboration(newValue) {
      if (newValue === false && this.currentTab === 'users') {
        this.currentTab = 'ongoing'
      }
    },
  },
  created() {
    this.getOngoingChats()
    const chatChannelHandler = (payload) => {
      const channel = transformRoom(payload)
      if (channel.members.indexOf(this.user.id) === -1) {
        return
      }
      if (channel.status === 'on_going') {
        this.ongoingChats = [...this.ongoingChats, channel]
      } else {
        this.ongoingChats = this.ongoingChats.filter((r) => r.id !== channel.id)
      }
    }
    Bus.$on('chat_channel_updated', chatChannelHandler)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('chat_channel_updated', chatChannelHandler)
    })
    this.socket.on(
      `${this.eventPrefix}-transfer_completed`,
      this.getOngoingChats
    )
  },
  methods: {
    getOngoingChats() {
      getOngoingChatApi(this.user.id).then((data) => {
        this.ongoingChats = data
      })
    },
    handleUserClicked(data) {
      if (data.user) {
        this.$emit('room-click', data)
        return
      }
      this.$emit('click', data.id)
    },
  },
}
</script>
