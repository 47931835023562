<template>
  <MRow :gutter="0" class="flex items-center">
    <MCol class="min-w-0 flex-1 mx-2 flex flex-col">
      <h5 class="text-ellipsis m-0"> {{ approval.subject }} </h5>
      <small class="text-neutral-light">
        {{ $t('by') }}
        <FlotoUserDrawer :disabled="disabled" :user-id="approval.owner" />
        {{ $t('at') }}
        {{ approval.createdAt | datetime }}
      </small>
    </MCol>
    <MCol class="flex flex-col1" auto-size>
      <small v-if="approval.totalStage > 1" class="mr-2">
        {{ $tc('stage', approval.totalStage) }}:
        {{ approval.activeStage }}
        /
        {{ approval.totalStage }}
      </small>
      <MTag
        v-if="approval.approvalType && approval.totalStage === 1"
        variant="neutral-lighter"
        class="cursor-default"
        :closable="false"
      >
        {{ $t(approval.approvalType) }}
      </MTag>
      <StatusIcon :status="approval.status" />
    </MCol>
  </MRow>
</template>

<script>
import StatusIcon from './approval-status-icon'

export default {
  name: 'ApprovalHeader',
  components: { StatusIcon },
  props: {
    approval: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
  },
}
</script>
