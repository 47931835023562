<template>
  <div class="ai-bot">
    <AIBotLanding
      v-if="!showChatBox"
      v-bind="$attrs"
      @start-chat="startChat"
      @view-conversation="startChat"
    />
    <AIBotChatBox
      v-else
      :key="renderCount"
      :room="room"
      :default-unread-count="0"
      default-visible
      :can-minimize="false"
      :default-messages="defaultMessages"
      :owner="user || {}"
      v-bind="$attrs"
      @on-minimize="showChatBox = false"
      @back-to-landing="showChatBox = false"
      @start-new-conversation="handleStartNewConversation"
    />
  </div>
</template>

<script>
import Reverse from 'lodash/reverse'
import { authComputed } from '@state/modules/auth'
import AIBotLanding from './ai-bot-landing.vue'
import AIBotChatBox from './ai-bot-chat-box'
import { clearOldAndCreateNewChatSessionApi } from '../chat-api'
export default {
  name: 'AIBot',
  components: { AIBotLanding, AIBotChatBox },
  props: {
    ongoingChat: { type: Boolean, default: false },
    channel: {
      type: Object,
      default() {
        return {}
      },
    },
    historyMessages: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showChatBox: true, // this.ongoingChat,
      room: this.channel,
      defaultMessages: this.historyMessages,
      renderCount: 1,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    'room.id': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.renderCount++
        }
      },
    },
  },
  methods: {
    handleStartNewConversation() {
      clearOldAndCreateNewChatSessionApi().then((data) => {
        this.room = data.channel
        this.defaultMessages = Reverse(data.history || [])
        this.showChatBox = true
        this.$emit('start-new-conversation', data)
      })
    },
  },
}
</script>
