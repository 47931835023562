<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :columns="columns"
    :fetch-fn="getApprovers"
    :update-fn="updateApprover"
    :per-page="10"
    class="h-100"
  >
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:name="{ item }">
      <td class="text-ellipsis">
        {{ item.email ? `${item.name} (${item.email})` : item.name }}
      </td>
    </template>
    <template v-slot:status="{ item }">
      <td
        :class="{
          flex: true,
          'flex-wrap': item.status !== 'pending',
          'items-center': true,
        }"
      >
        <StatusIcon :status="item.status" show-status size="lg" />
        <div class="w-full">
          <small v-if="item.status !== 'pending'" class="text-neutral">
            {{ $t('by') }}
            <FlotoUserDrawer
              v-if="item.approvedUserId"
              :user-id="item.approvedUserId"
              :disabled="isPortalLogin"
            />
            <span v-else class="text-primary">System</span>
            {{ $t('at') }}
            {{ item.updatedAt | datetime }}
          </small>
        </div>
        <div
          v-if="item.status === 'pending' && !hideAction"
          class="flex items-center mt-1"
        >
          <FlotoDeleteBtn
            id="ignore-btn"
            :message="$t('confirm_ignore')"
            @confirm="ignoreApprover(item.id)"
          >
            <template v-slot:trigger>
              <a>
                {{ $t('ignore') }}
              </a>
            </template>
          </FlotoDeleteBtn>
          <span class="mx-2">|</span>
          <a id="remind-btn" @click="remindApproval(item.id)">
            {{ $t('remind') }}
          </a>
        </div>
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { TechnicianComputed } from '@state/modules/technician'
import { authComputed } from '@state/modules/auth'
import {
  getApproversApi,
  updateApproverApi,
  remindApproverApi,
  ignoreApproverApi,
} from '../approval-api'
import StatusIcon from './approval-status-icon'

export default {
  name: 'ApproverList',
  components: { StatusIcon },
  props: {
    stageId: { type: Number, required: true },
    disabled: { type: Boolean, required: true },
    hideAction: { type: Boolean, default: false },
  },

  data() {
    this.columns = [
      {
        name: `${this.$tc('approver')}`,
        key: 'name',
      },
      ...(this.disabled
        ? []
        : [
            {
              name: this.$tc('status'),
              key: 'status',
              // width: '200px',
              // minWidth: '150px',
            },
          ]),
    ]
    return {}
  },
  computed: {
    ...TechnicianComputed,
    ...authComputed,
  },
  methods: {
    getApprovers(limit, offset) {
      return getApproversApi(this.stageId, limit, offset)
    },
    updateApprover(data) {
      return updateApproverApi(data).then((response) => {
        this.$emit('approver-action-taken')
        return response
      })
    },
    remindApproval(id) {
      return remindApproverApi(id)
    },
    refresh() {
      this.$refs.list.refresh()
    },

    ignoreApprover(id) {
      return ignoreApproverApi(id).then((response) => {
        this.$emit('approver-action-taken')
        this.refresh()
        return response
      })
    },
  },
}
</script>
