<template>
  <div
    ref="messagesContainer"
    class="scroll-wrapper"
    @scroll="handleMessagesScroll"
  >
    <div class="users-container">
      <div v-if="loadingHistory" class="history-loader">
        <MIcon name="spinner-third" class="fa-spin" size="lg" />
      </div>
      <template v-if="(messages || []).length">
        <Message
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :attachments="message.fileAttachments"
          :sender-id="message.owner"
          :participant="owner"
          :requester="requester"
          :is-my-message="
            !isPortalLogin && message.owner === -93101
              ? true
              : message.owner === owner.id
          "
          :date="message.date"
        />
      </template>
      <div v-if="!loadingHistory && showNoData && (messages || []).length <= 0">
        <FlotoNoData size="small" />
      </div>
    </div>
  </div>
</template>

<script>
import Reverse from 'lodash/reverse'
import UniqBy from 'lodash/uniqBy'
import { authComputed } from '@state/modules/auth'
import { getChatHistoryApi } from '../chat-api'
import Message from './message.vue'

export default {
  name: 'ChatMessages',
  components: { Message },
  props: {
    room: { type: Object, default: undefined },
    chatLimit: { type: Number, default: 50 },
    owner: { type: Object, required: true },
    requester: { type: Object, default: undefined },
    socket: { type: Object, default: undefined },
    eventPrefix: { type: String, default: '' },
    defaultMessages: {
      type: Array,
      default() {
        return []
      },
    },
    showNoData: { type: Boolean, default: false },
  },
  data() {
    this.messageBoxScrollPosition = 0
    return {
      loadingHistory: false,
      messages: this.defaultMessages || [],
      lastOffset: 0,
      hasMoreMessages: true,
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    if (this.room) {
      this.getChatHistory(this.lastOffset, this.chatLimit)
    }
    if (this.socket) {
      this.socket.on(`${this.eventPrefix}-message`, (message) => {
        if (this.room && this.room.id === message.roomId) {
          this.messages = UniqBy([...this.messages, message], 'id')
          this.$nextTick(() => this.resetScrollToBottom())
        }
      })
    }
  },
  methods: {
    getMessages() {
      return this.messages
    },
    handleMessagesScroll(e) {
      if (e.target.scrollTop === 0) {
        this.getChatHistory(this.lastOffset, this.chatLimit)
      }
    },
    getChatHistory(offset, limit) {
      if (!this.hasMoreMessages || this.loadingHistory) {
        return
      }
      this.loadingHistory = true
      this.saveMessageBoxScrollPosition(offset / limit || 1)
      getChatHistoryApi(this.room.id, offset, limit).then((data) => {
        const orderedMessages = Reverse(data.messages)
        this.messages = UniqBy([...orderedMessages, ...this.messages], 'id')
        this.loadingHistory = false
        this.lastOffset = offset + limit
        this.hasMoreMessages = data.total > offset + limit
        this.$nextTick(() => {
          // if first time loading we scroll to bottom
          if (offset === 0) {
            this.resetScrollToBottom()
          } else {
            this.restoreMessageBoxScrollPosition()
          }
        })
      })
    },
    resetScrollToBottom() {
      if (this.$refs.messagesContainer) {
        this.$refs.messagesContainer.scrollTop =
          this.$refs.messagesContainer.scrollHeight
      }
    },
    saveMessageBoxScrollPosition(pageNo) {
      this.messageBoxScrollPosition =
        this.$refs.messagesContainer.scrollHeight / pageNo
    },
    restoreMessageBoxScrollPosition() {
      this.$refs.messagesContainer.scrollTop = this.messageBoxScrollPosition
    },
  },
}
</script>
